import "./App.css";

import axios from "axios";
import { useEffect, useState } from "react";
import logo from "./logo.png";

const hoy = new Date();
hoy.setHours(0, 0, 0, 0);

function App() {
  const [socio, setSocio] = useState();
  const [dni, setDNI] = useState("");
  const [error, setError] = useState(false);
  const [manualInput, setManualInput] = useState("");
  const [loading, setLoading] = useState();
  const searchParams = new URLSearchParams(document.location.search);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!manualInput.includes(".")) {
      setDNI(manualInput);
    } else {
      return;
    }
    setManualInput("");
  };

  const handleChange = (e) => {
    setManualInput(e.target.value);
  };

  useEffect(() => {
    const posibleDni = searchParams.get("dni");
    if (posibleDni !== "") {
      setDNI(posibleDni);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dni) {
      setLoading(true);
      axios
        .get("https://apeffa.com/afiliado/" + dni)
        .then((response) => {
          const { data } = response;
          const date = new Date(data.credencialValidaHasta);
          date.setUTCHours(23, 59, 59);
          setSocio({
            dni: data.dni,
            nombre: data.nombre,
            fechaIngreso: data.fechaIngreso,
            asociado: data.asociado,
            credencialValidaHasta: data.credencialValidaHasta && date,
          });
          setError(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setError(true);
          setLoading(false);
        });
    }
  }, [dni]);
  const credencialValida = socio?.credencialValidaHasta >= hoy;
  const fechaValidez = socio?.credencialValidaHasta?.toLocaleDateString(
    "es-AR",
    { day: "2-digit", month: "2-digit", year: "numeric" }
  );

  return (
    <div className="App">
      <header className="bg-[#F3F6F9] py-2 text-[#07327c] flex flex-row flex-nowrap">
        <img src={logo} alt="APEFFA" className="pl-2 max-h-20" />
        <h2 className="text-sm sm:text-xl pl-3 align-bottom flex-basis-1/2 self-end">
          Asociación de Profesores de Educación Física del Fútbol Argentino
        </h2>
      </header>
      <div className="mt-5 mx-auto max-w-sm  ">
        <h4 className="text-lg font-bold">Consulta de socio</h4>
        <form className="block w-full">
          <label htmlFor="dni" className="block text-gray-700">
            DNI
            <input
              name="dni"
              placeholder="Sin puntos"
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              value={manualInput}
              onChange={handleChange}
            />
          </label>
          <button
            onClick={handleSubmit}
            className="rounded-full mt-2 relative right-0 bg-[#07327c] text-white px-4 py-2 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:bg-blue-800"
          >
            Consultar
          </button>
        </form>

        <div className="flex justify-center items-center flex-col pt-5">
          {loading && <p>Cargando...</p>}
          {!loading && dni && error && (
            <p className="text-2xl font-bold">
              No se encontró ningún socio con DNI {dni}.
            </p>
          )}
          {!loading && socio && (
            <>
              <p className="text-2xl font-bold">{socio.nombre}</p>
              <p className="text-xl pb-2">DNI: {socio.dni}</p>
            </>
          )}
          {!loading && socio && credencialValida && (
            <>
              <h1 className="text-9xl">✅</h1>
              <p className="pt-5 text-center block text-lg">
                Credencial de socio APEFFA <b>válida</b> hasta {fechaValidez}
              </p>
            </>
          )}
          {!loading && socio && !credencialValida && (
            <>
              <h1 className="text-9xl">❌</h1>
              <p className="pt-5 text-center block text-lg">
                Credencial de socio APEFFA <b>inválida</b> desde {fechaValidez}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
